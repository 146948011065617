import React from "react";
import type { NextPage } from "next";
import {
	Button,
	Flex,
	Link,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import { Logo } from "~/components/Logo";

const HomePage: NextPage = () => (
	<Flex
		w="100%"
		justifyContent="center"
		alignItems="center"
		flexDir="column"
		mt={5}
	>
		<Logo />
		<Text fontSize={25}>{strings.home.description}</Text>
		<Flex
			flexDir="column"
			w="40%"
			alignItems="center"
		>
			<Link
				minW={330}
				w="100%"
				href={strings.home.links.link1}
				isExternal
			>
				<Button
					w="100%"
					py={6}
					my={5}
					fontSize={{
						base: 16,
						lg: 20,
					}}
				>
					{strings.home.textButtons.text1}
				</Button>
			</Link>
			<Link
				minW={330}
				w="100%"
				href={strings.home.links.link2}
				isExternal
			>
				<Button
					w="100%"
					py={6}
					mb={5}
					fontSize={{
						base: 16,
						lg: 20,
					}}
				>
					{strings.home.textButtons.text2}
				</Button>
			</Link>
			<Link
				minW={330}
				w="100%"
				href={strings.home.links.link3}
				isExternal
			>
				<Button
					w="100%"
					py={6}
					mb={5}
					fontSize={{
						base: 16,
						lg: 20,
					}}
				>
					{strings.home.textButtons.text3}
				</Button>
			</Link>
			<Link
				minW={330}
				w="100%"
				href={strings.home.links.link4}
				isExternal
			>
				<Button
					w="100%"
					py={6}
					mb={5}
					fontSize={{
						base: 16,
						lg: 20,
					}}
				>
					{strings.home.textButtons.text4}
				</Button>
			</Link>
		</Flex>
	</Flex>
);

export default HomePage;
