import React from "react";
import { Box } from "@chakra-ui/react";
import Image from "next/image";
import { observer } from "mobx-react-lite";
import logo from "../../public/img/logo.svg";

export const Logo: React.FC = observer(() => (
	<Box
		w={150}
		mb={10}
		mt={10}
	>
		<Image
			src={logo}
			alt="Logo da LOC"
		/>
	</Box>
));
